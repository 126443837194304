import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import 'normalize.css';
import '../styles/main.scss';
// import { ThemeProvider } from './ThemeContext';
import { ModalProvider } from './ModalContext';
import { SnackbarProvider } from './SnackbarContext';
import Snackbar from './Snackbar';
import Modal from './Modal';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  useEffect(() => {
    // Accessibility Features
    // Let the document know when the mouse is being used
    const mousedownListener = () => {
      document.body.classList.add('is-mouse');
      document.body.classList.remove('is-keyboard');
    };

    // Re-enable focus styling when Tab is pressed
    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.remove('is-mouse');
        document.body.classList.add('is-keyboard');
      }
    };

    document.body.addEventListener('mousedown', mousedownListener);
    document.body.addEventListener('touchstart', mousedownListener);
    document.body.addEventListener('keydown', keydownListener, true);

    return () => {
      document.body.removeEventListener('mousedown', mousedownListener);
      document.body.removeEventListener('touchstart', mousedownListener);
      document.body.removeEventListener('keydown', keydownListener, true);
    };
  }, []);

  return (
    <>
      {/* <ThemeProvider> */}
      <ModalProvider>
        <SnackbarProvider>
          <div className="site">
            <Header />
            <div className="site-content">{children}</div>
            <Footer />
          </div>
          <Snackbar />
        </SnackbarProvider>
        <Modal />
      </ModalProvider>
      {/* </ThemeProvider> */}
    </>
  );
}
