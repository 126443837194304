import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import Logo from './Logo';
import { useWindowSize } from '../hooks/useWindowSize';
import useOnClickOutside from '../hooks/useOnClickOutside';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const mobileMenuWrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize(); // Window Width

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }

    if (menuOpen && width && width > 1024) {
      setMenuOpen(false);
    }
  }, [menuOpen, width]);

  useOnClickOutside(mobileMenuWrapperRef, () => setMenuOpen(false));

  return (
    <header className="header container">
      <div className="header__inner">
        <Link to="/" className="logo">
          <Logo />
        </Link>
      </div>
    </header>
  );
}
