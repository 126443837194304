import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { IoLogoTwitter } from 'react-icons/io';
import { RiFacebookFill } from 'react-icons/ri';
import { FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'gatsby';
import Logo from './Logo';
// import Colophon from './Colophon';
// import Toggle from './ThemeToggle';

export default function Footer() {
  return (
    <>
      <footer className="footer container">
        <div className="footer__inner">
          <div className="footer__top">
            <div className="footer__logo">
              <Logo />
            </div>
            <div className="footer__address">
              <div className="contact-info">
                <div className="contact-info__icon">
                  <MdLocationOn />
                </div>
                <div className="contact-info__text">
                  <p>
                    14785 Preston Rd, #1000
                    <br />
                    Dallas, Texas
                    <br />
                    75254, US
                  </p>
                </div>
              </div>
            </div>
            <div className="footer__social">
              <ul>
                <li>
                  <a href="https://www.facebook.com/FaithInvestorServices/">
                    <RiFacebookFill title="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/fis_etfs">
                    <IoLogoTwitter title="Twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/faith-investor-services/">
                    <FaLinkedinIn title="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__links">
              <ul>
                <li>
                  <a
                    href="https://faithinvestorservices.com/wp-content/uploads/2021/07/FIS-Website-Privacy-Policy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__disclosures">
              <h4>Disclosures</h4>
              <p>
                <strong>
                  Carefully consider investment objectives, risk factors, and
                  charges and expenses before investing. This and other
                  information can be found in the KOCG Prospectus available by{' '}
                  <a
                    href="https://faithinvestorservices.com/wp-content/uploads/2021/07/FIS-Knights-of-Columbus-Global-Belief-ETF-Prospectus.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                  >
                    clicking here
                  </a>
                </strong>
              </p>
              <p>
                <strong>Read all materials carefully before investing.</strong>
              </p>
              <p>
                <strong>Fund Risks:</strong>
                <br />
                There is no guarantee the Fund’s investment strategy will be
                successful and you can lose money on your investment in the
                fund. Shares may trade at a premium or discount to their NAV in
                the secondary market. The fund is new and has limited operating
                history to judge.
              </p>
              <p>
                <strong className="underline">Market Risk.</strong> The prices
                of securities held by the Fund may decline in response to
                certain events taking place around the world, including those
                directly involving the companies whose securities are owned by
                the Fund; conditions affecting the general economy; overall
                market changes; local, regional or global political, social or
                economic instability; and currency, interest rate and commodity
                price fluctuations.{' '}
                <strong className="underline">
                  Emerging Markets Securities Risk.
                </strong>{' '}
                The Fund’s investments in emerging markets securities are
                considered speculative and subject to heightened risks in
                addition to the general risks of investing in foreign
                securities. Unlike more established markets, emerging markets
                may have governments that are less stable, markets that are less
                liquid and economies that are less developed.{' '}
                <strong className="underline">Active Management Risk.</strong>{' '}
                The Fund is actively managed, which means that investment
                decisions are made based on investment views. There is no
                guarantee that the investment views will produce the desired
                results or expected returns, which may cause the Fund to fail to
                meet its investment objective or to underperform its benchmark
                index or funds with similar investment objectives and
                strategies.{' '}
                <strong className="underline">
                  Catholic Values Investing Risk.
                </strong>{' '}
                The Fund considers the USCCB Guidelines in its investment
                process and may choose not to purchase, or may sell, otherwise
                profitable investments in companies which have been identified
                as being in conflict with the USCCB Guidelines. This means that
                the Fund may underperform other similar mutual funds that do not
                consider the USCCB Guidelines when making investment decisions.
              </p>
              <p>
                <em>Distributed by Foreside Fund Services, LLC.</em>
              </p>
            </div>
          </div>
          {/* <ThemeToggle /> */}
          {/* <Colophon /> */}
        </div>
      </footer>
    </>
  );
}
